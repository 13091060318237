import React, {FC, useEffect, useMemo, useState} from "react";
import styles from "./shutdown-plant.module.scss";
import {Button, Form} from 'react-bootstrap';
import {GiaTextField} from '../gia-forms-components/gia-forms-components';
import {PlantShutdownReasonInterface} from './interfaces/plant-shutdown-reason.interface';
import {PlantShutdownDetailInterface} from './interfaces/plant-shutdown-detail.interface';
import plantShutdownDetailSchema from "./plant-shutdown-detail.schema";
import {toast} from 'react-toastify';
import {SubmitHandler, useForm} from "react-hook-form";
import axios from 'axios';
import {z} from "zod";
import {zodResolver} from "@hookform/resolvers/zod";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPowerOff, faArrowsRotate} from '@fortawesome/free-solid-svg-icons';
import DataTable from "react-data-table-component";
import CSS from 'csstype';
import moment from 'moment';
import 'moment/locale/pt-br';
import UserService from "../../modules/auth/UserService";
import { Roles } from "../../modules/auth/roles";
import { isConstructorDeclaration } from "typescript";

moment.locale('pt-br');

interface ShutdownPlantProps {
  plantId: number | undefined,
}

interface DatatableRowInterface {
  id: BigInteger,
  deviceSn: string,
  shutdownType: string,
  shutdownStatus: string,
  shutdownProcessStatus: string,
  shutdownDate: string,
  shutdownMsg: string,
}

export default function ShutdownPlant({plantId}: Readonly<ShutdownPlantProps>) {
  const [isLoading, setIsLoading] = useState(false);
  const [datasource, setDatasource] = useState<DatatableRowInterface[]>([]);
  const [plantShutdownReason, setPlantShutdownReason] = useState<PlantShutdownReasonInterface[]>([]);
  const [plantShutdownDetail, setPlantShutdownDetail] = useState<PlantShutdownDetailInterface>({
    shutdownType: 'NONE',
    shutdownStatus: true,
    shutdownDate: '',
    shutdownReason: '',
    plantShutdownReasonId: 0,
  });

  const hasRolePlantsShutdown = UserService.hasRole([Roles.PLANTS_SHUTDOWN]);

  type FormSchemaType = z.infer<typeof plantShutdownDetailSchema>;

  const {
    register,
    setValue,
    handleSubmit,
    formState: {errors}
  } = useForm<FormSchemaType>({
    mode: 'all',
    resolver: zodResolver(plantShutdownDetailSchema)
  });
  
  const [labelReason, setLabelReason] = useState("");
  
  useEffect(() => {
    const intervalId = setInterval(() => {
      axios.get(`${process.env.REACT_APP_BACKEND_API_URL}/plant/shutdown-details/${plantId}`).then(r => r.data).then(response => {
        setDatasource(convertResponse(response.devices))
      });
    }, 5000);
    
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    //setValue('shutdownReason', plantShutdownDetail.shutdownReason);
    //setValue('plantShutdownReason', plantShutdownDetail.plantShutdownReasonId?.toString());

  }, [plantShutdownDetail]);

  useEffect(() => {
    if (!plantId) return;

    updateData();
  }, [plantId]);

  const defaultRowStyle: CSS.Properties = {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    WebkitLineClamp: 1,
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
  };
  
  const defaultRowStringCell = (str: string) => {
    return <span data-tag="allowRowEvents" style={defaultRowStyle} title={str}>{str}</span>;
  }
  
  const columns = useMemo(() => {
    const columnsArray = [
      {
        name: 'Device SN',
        selector: (row: any) => row.deviceSn,
        sortable: false,
        width: '20%',
        cell: (row: any) => defaultRowStringCell(row.deviceSn),
      },
      {
        name: 'Status',
        selector: (row: any) => row.shutdownStatus,
        sortable: false,
        width: '10%',
        cell: (row: any) => defaultRowStringCell(row.shutdownStatus),
      },
      {
        name: 'Data',
        selector: (row: any) => row.shutdownDate,
        sortable: false,
        width: '20%',
        cell: (row: any) => defaultRowStringCell(row.shutdownDate),
      },
      {
        name: 'Status de Desligamento',
        selector: (row: any) => row.shutdownProcessStatus,
        sortable: false,
        width: '20%',
        cell: (row: any) => defaultRowStringCell(row.shutdownProcessStatus),
      },
      {
        name: 'Tipo de Desligamento',
        selector: (row: any) => row.shutdownType,
        sortable: false,
        width: '20%',
        cell: (row: any) => defaultRowStringCell(row.shutdownType),
      },
      {
        name: '',
        cell: (row: any) => <FontAwesomeIcon size="lg" icon={faArrowsRotate} onClick={() => handleShutdownReprocessing(row)} />,
        width: '5%',
      },
      {
        name: '',
        cell: (row: any) => (
          <>
            {row.shutdownType === 'Off-Line' && (
              <FontAwesomeIcon
                size="lg"
                color={row.shutdownStatus === 'Ligado' ? 'red' : 'green'}
                icon={faPowerOff}
                onClick={() => handleDeviceShutdown(row)}
              />
            )}
          </>
        ),
        width: '5%',
      },
    ];
  
    return columnsArray.map(c => ({ ...c, minWidth: c.width, maxWidth: c.width }));
  }, []);
  
  const enumToString = (enumValue: any) => {
    enum status {
      AWAITING_PROCESSING = "Aguardando Processamento",
      PROCESSING = "Em Processamento",
      ERROR = "Processado com Erro",
      DONE = "Processado com Sucesso",
    }

    return status[enumValue] || enumValue;
  };

  const shutdownTypeEnum = (enumValue: any) => {
    enum status {
      ONLINE = "On-Line",
      "OFF-LINE" = "Off-Line",
      NONE = "Indefinido",
    }

    return status[enumValue] || enumValue;
  };

  const convertResponse = (response: any) => {
    return response
      .map((r: any) => {
        return {
          id: r.id,
          deviceSn: r.deviceSn,
          shutdownStatus: r.shutdownStatus ? "Ligado" : "Desligado",
          shutdownProcessStatus: enumToString(r.shutdownProcessStatus),
          shutdownDate: r.shutdownDate ? moment(r.shutdownDate).format('L HH:mm:ss') : '-',
          shutdownMsg: r.shutdownMsg ? r.shutdownMsg : '-',
          shutdownType: r.shutdownType ? shutdownTypeEnum(r.shutdownType) : '-',
        } as DatatableRowInterface;
      });
  }

  const updateData = async () => {
    try {
      setIsLoading(true);

      const [reasonResponse, detailResponse] = await Promise.all([
        axios.get(`${process.env.REACT_APP_BACKEND_API_URL}/plantShutdownReason`),
        axios.get(`${process.env.REACT_APP_BACKEND_API_URL}/plant/shutdown-details/${plantId}`)
      ]);
      
      setPlantShutdownReason(reasonResponse.data.filter(item => item.reasonType === (detailResponse.data.shutdownStatus ? 'SHUTDOWN' : 'TURNON') ));
      setPlantShutdownDetail(detailResponse.data);
      setDatasource(convertResponse(detailResponse.data.devices));

      var label = reasonResponse.data.filter(item => item.id === detailResponse.data.plantShutdownReasonId);
      
      setLabelReason( label.length > 0 ? label[0].description : "" );

    } catch (error) {
      console.error(error);
      toastMessage('Houve um problema ao atualizar os dados.', 'error');
    } finally {
      setIsLoading(false);
    }
  };

  const toastMessage = (text: string, type: "success" | "error") => {
    toast[type](text, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
      theme: "light",
    });
  };

  const handleShutdownReprocessing = (row: any) => {
    const confirm = window.confirm('Deseja realmente reprocessar o device?');
    if (confirm) {
      setIsLoading(true);

      axios.put(`${process.env.REACT_APP_BACKEND_API_URL}/plant-device/shutdown-reprocessing`, [row.id])
        .then((response) => {
          if (response.status === 200) {
            toastMessage('Device em reprocessamento!', 'success');
            updateData();
          } else if (response.status === 404) {
            toastMessage('Device não encontrado!', 'error');
          } else {
            toastMessage(response.statusText, 'error');
          }
        }).catch(() => {
          toastMessage('Houve um problema ao reprocessar o device! Por favor tente novamente.', 'error');
        }).finally(() => {
          setIsLoading(false);
        });
    }
  };

  const handleDeviceShutdown = (row: any) => {
    const { shutdownStatus } = row;
    const confirmMessage = shutdownStatus === "Ligado" ? 'Deseja realmente desligar o device?' : 'Deseja realmente ligar o device?';

    const confirm = window.confirm(confirmMessage);
    if (confirm) {
      setIsLoading(true);

      axios.put(`${process.env.REACT_APP_BACKEND_API_URL}/plant-device/shutdown`, [row.id])
        .then((response) => {
          if (response.status === 200) {
            const message = shutdownStatus === "Ligado" ? 'Device desligado!' : 'Device ligado!';
            toastMessage(message, 'success');
            updateData();
          } else if (response.status === 404) {
            toastMessage('Device não encontrado!', 'error');
          } else {
            toastMessage(response.statusText, 'error');
          }
        }).catch(() => {
          const message = shutdownStatus === "Ligado" ? 'Houve um problema ao desligar o device!' : 'Houve um problema ao ligar o device!';
          toastMessage(message + ' Por favor tente novamente.', 'error');
        }).finally(() => {
          setIsLoading(false);
        });
    }
  };

  const onSubmit: SubmitHandler<FormSchemaType> = (data) => {
    const { shutdownStatus } = plantShutdownDetail;
    const disable = shutdownStatus ? true : false;
    const message = shutdownStatus ? 'Usina desligada com sucesso!' : 'Usina religada com sucesso!';

    const confirmMessage = shutdownStatus ? 'Deseja realmente desligar a usina?' : 'Deseja realmente religar a usina?';
    
    if (data.plantShutdownReason != '0' || !plantShutdownDetail.shutdownStatus) {

      const confirm = window.confirm(confirmMessage);
      if (confirm) {
        setIsLoading(true);
        
        axios.post(`${process.env.REACT_APP_BACKEND_API_URL}/plant/shutdown/${plantId}`, {
          disable,
          plantShutdownReasonId: data.plantShutdownReason != '0' ? data.plantShutdownReason : null,
          shutdownReason: data.shutdownReason
        }).then((response) => {
          if (response.status === 200) {
            toastMessage(message, 'success');
            setValue('plantShutdownReason', "");
            setValue('shutdownReason', "");
            updateData();
          } else if (response.status === 404) {
            toastMessage('Usina não encontrada!', 'error');
          } else {
            toastMessage(response.statusText, 'error');
          }
        }).catch(() => {
          toastMessage(`Houve um problema ao ${shutdownStatus ? 'desligar' : 'religar'} a usina! Por favor tente novamente.`, 'error');
        }).finally(() => {
          setIsLoading(false);
        });
      }
    }else{
      toastMessage('Selecione um motivo', 'error');
    }
  };

  return (
    <>
      {plantShutdownDetail?.shutdownType != 'NONE' && hasRolePlantsShutdown &&
        <Form noValidate onSubmit={handleSubmit(onSubmit)} className="gia-form">
          <div className="card">
            <div className="title">
              Opções de {plantShutdownDetail.shutdownStatus? "Desligamento" : "Religamento"}
            </div>
            <div className="body mt-3">
              <div className="item">
                <div className="content">
                  <label className='label required'>Motivo do {plantShutdownDetail.shutdownStatus? "Desligamento" : "Religamento"}</label>
                  <Form.Select title="Motivo"
                    disabled={isLoading}
                    isInvalid={plantShutdownDetail.shutdownStatus ? !!errors.plantShutdownReason : false}
                    {...register('plantShutdownReason')}>
                    {<option key={0} value={0}>Selecione...</option>}
                    {plantShutdownReason.map((option, i) => (
                      <option key={option.id} value={option.id}>{option.description}</option>
                    ))}
                  </Form.Select>
                    <div className="gia-form-feedback-message"></div>
                </div>
              </div>

              <div className="item mt-4">
                <div className="content">
                <label className='label required'>Razão do {plantShutdownDetail.shutdownStatus? "Desligamento" : "Religamento"}</label>
                <GiaTextField
                  type="text"
                  isInvalid={!!errors.shutdownReason}
                  isInvalidText={errors?.shutdownReason?.message}
                  disabled={isLoading}
                  max={255}
                  registerName={{...register('shutdownReason')}}
                  placeholder="Razão" />
                </div>
              </div>

              <div className="item">
                <div className="content">
                <Button type="submit" variant={plantShutdownDetail.shutdownStatus? "danger" : "primary"} size="lg" disabled={isLoading}>{plantShutdownDetail.shutdownStatus? "Desligar" : "Religar"}</Button>
                </div>
              </div>

              <div className="row mt-4">
                <div className="d-flex">
                  <div className="item me-3">
                    <div className="content">
                      <div className="rdt_Table">Data</div>
                      <div style={{ fontSize: '14px' }}>{ plantShutdownDetail.shutdownDate != null ? new Date(plantShutdownDetail.shutdownDate).toLocaleDateString('pt-BR') + " " + new Date(plantShutdownDetail.shutdownDate).toLocaleTimeString('pt-BR') : ""}</div>
                    </div>
                  </div>
                  <div className="item me-3">
                    <div className="content">
                      <div className="rdt_Table">Motivo {plantShutdownDetail.shutdownStatus? "Religamento" : "Desligamento"}</div>
                      <div style={{ fontSize: '14px' }}>{ labelReason }</div>
                    </div>
                  </div>
                  <div className="item me-3">
                    <div className="content">
                      <div className="rdt_Table">Razão {plantShutdownDetail.shutdownStatus? "Religamento" : "Desligamento"}</div>
                      <div style={{ fontSize: '14px' }}>{plantShutdownDetail.shutdownReason}</div>
                    </div>
                  </div>
                </div>
              </div>  

            </div>
          </div>

          <div className={`${styles.datatableWrapper} gia-datatable-wrapper`}>
            <DataTable
              pointerOnHover={true}
              columns={columns}
              data={datasource}
              noDataComponent={<><span className="gia-datatable-no-results">Nenhum registro</span></>}
              fixedHeader={true}
              className="gia-datatable"
              persistTableHead
              progressPending={isLoading}
              theme="gia-default"
            />
          </div>
        </Form>
      }
    </>
  );
}
